export const offer_front = [
  {
    title: "zaburzeń nerwicowych",
    url: "./img/icon_nerwica.png",
    url_hover: "./img/icon_nerwica_hover.png",
    url_page: "zaburzenia-nerwicowe",
  },
  {
    title: "zaburzeń związanych z długotrwałym stresem",
    url: "./img/icon_stres.png",
    url_hover: "./img/icon_stres_hover.png",
    url_page: "zaburzenia-dlugotrwaly-stres",
  },
  {
    title: "kryzysów w życiu osobistym i zawodowym",
    url: "./img/icon_kryzys.png",
    url_hover: "./img/icon_kryzys_hover.png",
    url_page: "traumy-przypadki-losowe",
  },
  {
    title: "radzenia sobie z traumami  spowodowanymi  wypadkami losowymi",
    url: "./img/icon_wypadki.png",
    url_hover: "./img/icon_wypadki_hover.png",
    url_page: "kryzys-w-zyciu-osobistym-i-zawodowym",
  },
  {
    title: "zaburzeń osobowości",
    url: "./img/icon_zaburzenie.png",
    url_hover: "./img/icon_zaburzenie_hover.png",
    url_page: "zaburzenia-osobowosci",
  },
  {
    title: "terapia grupowa",
    url: "./img/icon_group_therapy.png",
    url_hover: "./img/icon_group_therapy_hover.png",
    url_page: "terapia-grupowa",
  },
  {
    title: "Biofeedback",
    url: "./img/icon_biofeedback.png",
    url_hover: "./img/icon_biofeedback_hover.png",
    url_page: "biofeedback",
  },
];
