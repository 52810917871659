import React from "react";

const renderItems = (items) => items.map((item, i) => <p key={i}>{item}</p>);

const Personal = ({ imie, url_img, opis, opis_main, message }) => {
  return (
    <div className="personal">
      <div className="wrapper">
        <div className="imie">{imie}</div>
        <p>
          <div>
            <img
              src={url_img}
              alt="Image 1"
              className="personal__img"
              style={{ width: "400px" }}
            />
          </div>

          <div className="opis">{opis}</div>
          <div className="opis__main">{renderItems(opis_main)}</div>
          <div className="opis__msg">
            <blockquote className="blockquote">
              {renderItems(message)}
            </blockquote>
          </div>
        </p>
        <div className="personal__btn">
          <a className="button" href="/about">
            Powrót
          </a>
        </div>
      </div>
    </div>
  );
};

export default Personal;
