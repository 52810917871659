export const cennik = [
  {
    title: "Konsultacja diagnostyczno-terapeutyczna",
    cena: "150,00",
    czas: "40",
    remark: "Cena konsultacji on-line także 150,00 PLN",
  },
  {
    title: "Interwencja kryzysowa",
    cena: "150,00",
    czas: "50",
    remark: "Cena sesji on-lina także 150,00 PLN",
  },
  {
    title: "Psychoterapia indywidualna",
    cena: "150,00",
    czas: "50",
    remark: "Cena sesji on-lina także 150,00 PLN",
  },
  {
    title: "Terapia  par",
    cena: "200,00",
    czas: "50",
    remark: "Cena sesji on-line także 200,00 PLN",
  },
  {
    title: "Terapia grupowa",
    cena: "50,00",
    czas: "90",
    remark: "",
  },
  {
    title: "Biofeedback",
    cena: "80,00-100,00",
    czas: "60",
    remark: "Kosz sesji diagnostycznej to 150,00 PLN ",
  },
];
