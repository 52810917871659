import React from "react";

const Osoba = ({ url_img, opis, imie, url }) => {
  return (
    <div className="onas-item">
      <img src={url_img} className="card-img-top" />
      <div className="onas-item__info">
        <div className="onas-item__name">
          <h3>{imie}</h3>
        </div>
        <div className="onas-item__opis">
          <p>{opis}</p>
        </div>
        <div className="btn-container">
          <a className="button" href={`/about/${url}`}>
            Zobacz więcej
          </a>
        </div>
      </div>
    </div>
  );
};

export default Osoba;
