import React, { useState } from "react";

export const OfferItem = ({ title, url, url_hover, url_page }) => {
  const [state, setState] = useState({ img: url });

  const dest =
    url_page === "biofeedback" ? `${url_page}.pdf` : `/oferta/${url_page}`;

  return (
    <div className="offer-item">
      <div className="box-icon">
        <a href={dest}>
          <img
            src={state.img}
            alt="type"
            style={{ width: "128px" }}
            onMouseEnter={() => {
              setState({ img: url_hover });
            }}
            onMouseOut={() => {
              setState({ img: url });
            }}
          />

          <div className="offer__title">
            <h4>{title}</h4>
          </div>
        </a>
      </div>
    </div>
  );
};

export default OfferItem;
