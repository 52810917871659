import React from "react";
import ContactForm from "./components/ContactForm";
import ContactDirect from "./components/ContactDirect";

export const Contact = () => (
  <div>
    <div className="contact">
      <div className="wrapper">
        <ContactForm />
        <ContactDirect />
      </div>
    </div>
  </div>
);
