import React from "react";

export const OfferExtendedItem = ({ title, text, message, textAlt }) => {
  return (
    <div className="offer-extended">
      <div className="wrapper">
        <div className="title">{title}</div>

        <div className="opis__main">
          <div>
            {text.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
          <div>
            {textAlt !== undefined ? (
              textAlt.map((item, i) => {
                return (
                  <div key={i} style={{ fontFamily: "Arial" }}>
                    <h4
                      style={{
                        color: "white",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {item.title}
                    </h4>
                    <div>{item.text}</div>
                  </div>
                );
              })
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="opis__msg">
          {message.length > 0 ? (
            <blockquote className="blockquote">
              {message.map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </blockquote>
          ) : (
            <div></div>
          )}
        </div>
        <div className="offer-extended__btn">
          <a className="button" href="/">
            Powrót
          </a>
        </div>
      </div>
    </div>
  );
};

export default OfferExtendedItem;
