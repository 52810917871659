export const offer_main = [
  {
    title: "Konsultacja diagnostyczno-terapeutyczna",
    text: "Konsultacja diagnostyczno-terapeutyczna to  jedno lub kilka spotkań,\
        których celem jest rozpoznanie problemu z  którym klient się zgłasza, postawienie wstępnej \
        diagnozy  oraz zapoznanie go z możliwościami  pomocy.  Efektem konsultacji jest  przedstawienie \
        klientowi wstępnego planu pracy lub - jeśli jest taka potrzeba - skierowanie go do  innego specjalisty.",
  },
  {
    title: "Interwencja kryzysowa",
    text: "Interwencja kryzysowa jest wsparciem dla osób znajdujących się w trudnej sytuacji w związku \
        z przeżytym właśnie zdarzeniem  traumatycznym  takim jak  rozwód, śmierć bliskiej osoby, czy inne \
        zdarzenia losowe.  Celem interwencji kryzysowej jest  wsparcie dla klienta pozwalające mu na  \
        przywrócenie równowagi emocjonalnej i odzyskanie poczucia bezpieczeństwa. Efektem interwencji\
        kryzysowej może być podjęcie przez klienta  terapii  właściwej dla  jego potrzeb",
  },
  {
    title: "Psychoterapia indywidualna",
    text: "Psychoterapia indywidualna  jest cyklem regularnych spotkań  klienta z  terapeutą \
        odbywających się zazwyczaj raz w tygodniu.  Psychoterapię indywidualną warto podjąć, gdy\
        doświadczamy dyskomfortu  lub cierpienia z takich powodów jak np.  długotrwały stres,\
        lęki i fobie, nerwice, zaburzenia w związku lub  innych relacjach interpersonalnych. \
        Na sesje psychoterapii indywidualnej zapraszamy po  uprzedniej konsultacji.",
  },
  {
    title: "Terapia par",
    text: "Terapia par jest  formą pomocy dla  partnerów będących w formalnym lub nieformalnym\
        związku i przeżywających  kryzys lub trudności spowodowane nową sytuacją życiową, długotrwałym\
        konfliktem, zdradą,  pełnieniem ról rodzicielskich itp. Terapia par wymaga uczestnictwa w sesjach \
        obojga partnerów. ",
  },
  {
    title: "Terapia grupowa",
    text: "Terapia grupowa jest interesującą alternatywą dla terapii indywidualnej. Może także stanowić ciekawe jej uzupełnienie.\
     Polega ona na regularnych spotkaniach tych samych osób (w CT Empatia preferujemy grupy zamknięte), które we współpracy z terapeutą prowadzącym grupę,\
      dyskutują na ważne dla nich tematy. Osoby te wspólnie rozwijają się przechodząc przez kolejne etapy leczenia.",
  },
];
