/* eslint-disable no-multi-str */
export const about_info = [
  {
    imie: "Roman Kraczla",
    url: "roman-kraczla",
    opis: "Socjolog  i terapeuta od ponad 15 lat zajmujący się pomocą psychologiczną.",
    url_img: "/img/Roman_Kraczla.png",
    opis_main: [
      "Obszarem moich zainteresowań  terapeutycznych są głównie zaburzenia emocjonalne, \
                    zaburzenia osobowości i  kryzysy w życiu rodzinnym i osobistym.\
                    Pracuję  w nurcie analizy transakcyjnej i psychologii behawioralno-poznawczej. \
                    Swoją pracę poddaję regularnej superwizji u doświadczonych  superwizorów.",
      "Grupą zawodową  do których w szczególności kieruję swoje usługi są managerowie średniego i \
                    wysokiego szczebla.",
    ],
    message: [
      "Szanowni Państwo,",
      "Jeśli Wasze życie nie  jest satysfakcjonujące, jeśli doświadczacie trudności w utrzymywaniu dobrych relacji z innymi, \
                jeśli cierpicie z powodu poczucia niskiej wartości lub braku stabilności emocjonalnej, \
                to zapraszam do kontaktu. Razem znajdziemy sposób, by Wasze życie było satysfakcjonujące i szczęśliwe!",
    ],
  },
  {
    imie: "Prof. nadzw. dr hab. Magdalena Kraczla",
    url: "magdalena-kraczla",
    opis: "Psycholog zdrowia i psycholog biznesu",
    url_img: "/img/Magdalena_Kraczla.png",
    opis_main: [
      "Specjalizuje się w problematyce stresu prywatnego i zawodowego, odporności psychicznej, braku pewności siebie, negatywnych i pesymistycznych postaw życiowych, wypalenia zawodowego, kryzysu wieku średniego, kształtowania osobistej kariery zawodowej oraz w diagnozie osobistego potencjału wyrażonego w predyspozycjach osobowościowych. Pracuje  głównie w nurcie psychologii  behawioralno-poznawczej  jednak za każdym razem dostosowuje metodę terapii do problemu i potrzeb Klienta.",
      "Odbyła staż w jednej z najlepszych w Polsce placówek terapeutycznych w Klinice Nerwic Instytutu Psychiatrii i Neurologii w Warszawie.",
      "Od ponad 20 lat świadczy pomoc psychologiczną osobom będącym w kryzysach życiowych, cierpiącym z powodu negatywnych postaw życiowych, niskiej samooceny, wypalenia zawodowego, zmagań z brakiem równowagi życiowej, z poczuciem przeciążania oraz sytuacji kryzysowych, będących następstwem rozwodów. Zajmuje się także  wspieraniem rozwoju osobistego  i tworzeniem poczucia dobrostanu. ",
    ],
    message: [
      "Zapraszam na spotkanie! Moje dwudziestoletnie doświadczenie w poradnictwie psychologicznym wskazuje, że każdy może uczynić swoje życie bardziej satysfakcjonującym. Czasami jednak, by tak się stało,  potrzebuje  pomocy.  Chętnie wesprę Was w tej drodze!",
    ],
  },
  {
    imie: "Aleksandra Jakóbska-Kuczek",
    url: "aleksandra-jakobska-kurczek",
    opis: "Psycholog i neuroterapeuta",
    url_img: "/img/Aleksandra_Jakobska_Kuczek.jpeg",
    opis_main: [
      "Jestem psychologiem, neuroterapeutą - specjalistą metody neurofeedback i badania QEEG. Ukończyłam Uniwersytet Śląski w Katowicach. Dodatkową wiedzę i nowe doświadczenia zdobywałam na wielu kursach i szkoleniach.",
      "Zafascynowana jestem Biofeedbackiem, a w szczególności jedną z jego modalności, jaką jest Neurofeedback. Tu można w czasie rzeczywistym, bazując na analizie fal mózgowych, trenować funkcjonowanie własnego mózgu. Neurofeedback zmienia wzorce połączeń mózgowych; umysł podąża za zmianami, tworząc nowe wzorce zaangażowania",
      "Wsparcie oferuję więc dzieciom (od 5 roku życia) i osobom dorosłym z problemami z koncentracją uwagi, z nadruchliwością, z chaosem myślowym, dzieciom z trudnościami w nauce, z zaburzeniami ze spektrum autyzmu, z zaburzeniami w rozwoju mowy jak i osobom z zaburzeniami emocjonalnymi (lęki, depresje, stany obniżonego nastroju, impulsywność, zachowania agresywne), osobom z zaburzeniami snu, etc. Wspólnie poszukamy przyczyn jak i skutecznych sposobów radzenia sobie z zaistniałymi trudnościami. ",
    ],
    message: ["Zapraszam do kontaktu!"],
  },
  {
    imie: "Dr Radosław Molenda",
    url: "radoslaw-molenda",
    opis: "Doktor psychologii",
    url_img: "/img/Radoslaw_Molenda.png",
    opis_main: [
      "Absolwent Wydziału Pedagogiki i Psychologii Uniwersytetu Śląskiego; adiunkt w Akademii WSB,  konsultant ds. pomocy psychologicznej (5-letnie doświadczenie na tym stanowisku), wpisany do Spisu Psychologów Polskich",
    ],
    message: [
      "Szanowni Państwo,",
      "oferuję pomoc  terapeutyczną w zakresie leczenia lęków i fobii, nerwic, zaburzeń psychosomatycznych, depresji  i innych zaburzeń psychicznych. W swojej pracy preferuję model pracy oparty o terapię ericksonowską w której się ciągle doskonalę  w  Polskim Instytucie Ericksonowskim. Regularnie superwizuję swoją pracę, aby zapewnić najwyższy standard oferowanych usług (superwizja w podejściu ericksonowskim odbywana w filii Polskiego Instytutu Ericksonowskiego w Katowicach).",
      "Niezależnie jaki jest Wasz problem potrafię cierpliwie wszystkiego wysłuchać i pomóc popatrzeć na sytuację w inny sposób. Mam dobry kontakt z osobami z którymi pracuję, potrafię wzbudzić ich zaufanie sprzyjające efektywnej pracy nad problemami. Umiem dostosować tempo i metody pracy do oczekiwań klienta, szanuję jego wybory.",
      "Zapraszam serdecznie do kontaktu i współpracy!",
    ],
  },
];
